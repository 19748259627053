.problem-error {
    background: url('material-design-icons/alert/svg/production/ic_error_18px.svg') left center no-repeat;
    list-style: none;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
}
.problem-warning {
    background: url('material-design-icons/alert/svg/production/ic_warning_18px.svg') left center no-repeat;
    list-style: none;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
}

.details {
    font-size: 80%;
}
.detailsToggle {
    padding: 0;
    font: inherit;
    font-size: 110%;
}
.detailsList {
    margin-left: 0.1rem;
    border-left: 0.1rem solid #aaa;
    padding-left: 0.5rem;
    display: grid;
    grid-template-columns: 5rem auto;
}
.detailsTitle {
    font-weight: bold;
    grid-column: 1;
}
.detailsValue {
    font-weight: bold;
    grid-column: 2;
}
